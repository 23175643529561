import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Alert,
  AlertTitle
} from '@mui/material';
import { Icon } from '@iconify/react';
import calendarIcon from '@iconify/icons-eva/calendar-outline';
import editIcon from '@iconify/icons-eva/edit-2-outline';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
// import USERLIST from '../_mocks_/user';
import { stringAvatar } from '../utils/avatar';
import { statusColor } from '../utils/status';
import useUserForm from 'src/components/UserForm';
import { getAllAppointmentList, getAppointmentList, getMemberList } from 'src/utils/apiUtil';
import useUpdateUserForm from 'src/components/UpdateUserForm';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import * as moment from 'moment'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import useTimeTableView from 'src/components/TimetableView';
// ----------------------------------------------------------------------
import Radio from '@mui/material/Radio';
import { shop } from 'src/constant/dropdown';

moment.locale('en-GB');
export default function Timetable() {

  const [myEventsList, setMyEventsList] = useState([]);
  const [allEventsList, setAllEventsList] = useState([]);
  const [timeTableView, setRow, handleClickOpen] = useTimeTableView();
  const [selectedValue, setSelectedValue] = useState('ALL');

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value !== 'ALL') {
      setMyEventsList(allEventsList.filter(item => item.resource.place === event.target.value));
    } else {
      setMyEventsList(allEventsList);
    }
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'color-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  useEffect(() => {
    getAllAppointmentList().then(data => {
      const temp = [];
      data?.map(item => {
        temp.push({
          title: item.title,
          start: new Date(item.from),
          end: new Date(item.to),
          allDay: false,
          resource: item
        })
      })
      setMyEventsList(temp);
      setAllEventsList(temp);
    })


  }, [])

  const stringToColour = (str) => {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var k = 0; k < 3; k++) {
      var value = (hash >> (k * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  const localizer = momentLocalizer(moment);

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = "";
    if (moment().diff(moment(start)) > 0) {
      backgroundColor = '#999999'
    } else {
      backgroundColor = 'green';
    }
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: moment().diff(moment(start)) > 0 ? 'black' : 'grey',
      border: '0px',
      display: 'block'
    };
    return {
      style: style
    };
  }

  const MyCalendar = () => {
    return <div>
      {timeTableView}
      <div style={{ margin: 10 }}>
        <FormControlLabel value={'ALL'} control={
          <Radio
            {...controlProps('ALL')}
          />
        } label={'全部分校'} />
        {
          shop.map((item, index) => {
            return <FormControlLabel key={index} value={item} control={
              <Radio
                {...controlProps(item)}
              />
            } label={item} />
          })
        }
      </div>
      <br />
      <Calendar
        titleAccessor={(event) => {
          return <div style={{ fontSize: 10, color: 'white' }}>
            開始時間: <b>{moment(event.resource?.from).format('HH:mm')}</b>
            <br />
            跟進人員: <b>{event.resource?.followerName}</b>
          </div>
        }}

        defaultView={'month'}
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        eventPropGetter={eventStyleGetter}

        onSelectEvent={(event) => {
          setRow(event.resource)
          handleClickOpen()
        }}
        style={{
          height: 800, width: '95%'
        }}
      />
    </div>
  }



  return (
    <>
      <Page title="Time Table | Dance Union">
        <Container>


          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              行事歷
            </Typography>
          </Stack>
          <Card>
            {
              myEventsList ? MyCalendar() : ""
            }
          </Card>
        </Container>
      </Page>
    </>
  );
}
