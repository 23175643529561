import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Box, Card, CardHeader } from '@mui/material';
// utils
import { fNumber } from '../../../utils/formatNumber';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------

export default function AppConversionRates({
  title,
  subheader,
  rows,
  cols
}) {
  const chartOptions = merge(BaseOptionChart(), {
    tooltip: {
      marker: { show: false },
      y: {
        formatter: (seriesName) => `${fNumber(seriesName)}秒`,
        title: {
          formatter: (seriesName) => `總共逗留：`
        }
      }
    },
    plotOptions: {
      bar: { horizontal: true, barHeight: '28%', borderRadius: 2 }
    },
    xaxis: {
      categories: cols.values
    },
  });

  return (
    <Card>
      <CardHeader
        title={title}
        subheader={subheader} />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart type="bar" series={[{ data: rows.values }]} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
