import * as Yup from 'yup';
import {useState} from 'react';
import {Link as RouterLink, useHistory} from 'react-router-dom';
import {useFormik, Form, FormikProvider} from 'formik';
import {Icon} from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import {withOktaAuth} from '@okta/okta-react';
import {OktaAuth} from '@okta/okta-auth-js';
import Alert from '@mui/material/Alert';
// material
import {
    Link,
    Stack,
    Checkbox,
    TextField,
    IconButton,
    InputAdornment,
    FormControlLabel
} from '@mui/material';
import {LoadingButton} from '@mui/lab';
import {OktaConfig} from 'src/constant/oktaConfig';
import {login} from 'src/utils/apiUtil';

// ----------------------------------------------------------------------

const oktaAuth = new OktaAuth(OktaConfig);

export default function LoginForm() {
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");

    const LoginSchema = Yup.object().shape({
        // email: Yup.string().email('Email must be a valid email address').required('Email is required'),
        email: Yup.string().required('Account is required'),
        password: Yup.string().required('Password is required')
    });

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            remember: true
        },
        validationSchema: LoginSchema,
        onSubmit: () => {
            signIn();
            // history.push('/dashboard');
        }
    });

    const {errors, touched, values, isSubmitting, setSubmitting, handleSubmit, getFieldProps} = formik;

    const handleShowPassword = () => {
        setShowPassword((show) => !show);
    };

    const signIn = async () => {
        try {
            // const transaction = await oktaAuth.signIn({
            //   username: values.email,
            //   password: values.password
            // });
            // if (transaction.status === 'SUCCESS') {
            //   oktaAuth.signInWithRedirect({ sessionToken: transaction.sessionToken })
            //   //transaction.user.profile
            // } else {
            //   throw new Error('Could not sign in: ' + transaction.status);
            // }

            const res = await login({
                username: values.email,
                password: values.password
            })
            setSubmitting(false);
            if (res.code === 0) {
                throw new Error('Could not sign in');
            } else {
                let { token } = res
                localStorage.setItem('dance-token', token);
                history.push('/user');
            }
        } catch (err) {
            setError(err.message);
            setSubmitting(false);
        }


    }

    return (
        <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={3}>
                    <TextField
                        fullWidth
                        autoComplete="username"
                        type="text"
                        label="Account address"
                        {...getFieldProps('email')}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                    />

                    <TextField
                        fullWidth
                        autoComplete="current-password"
                        type={showPassword ? 'text' : 'password'}
                        label="Password"
                        {...getFieldProps('password')}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleShowPassword} edge="end">
                                        <Icon icon={showPassword ? eyeFill : eyeOffFill}/>
                                    </IconButton>
                                </InputAdornment>
                            )
                        }}
                        error={Boolean(touched.password && errors.password)}
                        helperText={touched.password && errors.password}
                    />
                </Stack>
                {
                    error !== "" ? (
                        <Stack sx={{my: 2}}>
                            <Alert severity="error">{error}</Alert>
                        </Stack>
                    ) : ""
                }
                {/*
        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label="Remember me"
          />

          <Link component={RouterLink} variant="subtitle2" to="#">
            Forgot password?
          </Link>
        </Stack> */}

                <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{my: 2}}>
                    <LoadingButton
                        fullWidth
                        size="large"
                        type="submit"
                        variant="contained"
                        loading={isSubmitting}
                    >
                        Login
                    </LoadingButton>
                </Stack>
            </Form>
        </FormikProvider>
    );
}
