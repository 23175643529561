import { Route, Redirect} from 'react-router-dom';
export default function AuthRoute (props) {
    const Com = props.component
    const isAuthenticated = () => {
        return localStorage.getItem('dance-token') !== null;
    };

    return (
        <Route
            path={props.path}
            render={() => {
                if (isAuthenticated()) {
                    return <Com />
                } else {
                    return <Redirect to="/login" />
                }
            }}
        />
    )

}
