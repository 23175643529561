// material
import { Stack, TextField, IconButton, InputAdornment, MenuItem, FormControlLabel, Checkbox, Typography, FormLabel, InputLabel, Select, OutlinedInput, Box, Chip, FormControl, Card, Grid } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogActions } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { type, source, occupation, age, childAge, referral, region, gender, status } from '../constant/dropdown';
import { postAppointment, postMember, getMemberDetail } from 'src/utils/apiUtil';
import useLoading from './Loading';
import DatePickerField from './DatePickerField';
import "react-datepicker/dist/react-datepicker.css";
import Scrollbar from './Scrollbar';
import * as moment from 'moment';

export default function useTimeTableView(cb) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, handleLoadingOpen, handleLoadingClose] = useLoading();
    const [row, setRow] = useState({});
    const [memberInfo, setMemberInfo] = useState({});
    const [init, setInit] = useState(true);

    const handleClickOpen = () => {
        setOpen(true);
        setInit(true);
    };

    const handleClose = () => {
        setOpen(false);
        setInit(false);
    };

    useEffect(() => {
        if (init) {
            getMemberDetail(row.memberId).then(data => {
                setMemberInfo(data);
            });
            setInit(false);
        }

    })


    const timeTableView = (
        <div>
            <Dialog
                // fullScreen={fullScreen}
                maxWidth={'xl'}
                fullWidth
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {"會面"}
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <b>客戶姓名</b>:<br />
                            {memberInfo?.name}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <b>客戶性別</b>:<br />
                            {memberInfo?.gender}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <b>客戶電話</b>:<br />
                            {memberInfo?.phone}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <b>客戶電郵</b>:<br />
                            {memberInfo?.email}
                        </Grid>

                        <Grid item xs={12} sm={6} md={3}>
                            <b>查詢項目</b>:<br />
                            {memberInfo?.type}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <b>備注</b>:<br />
                            {memberInfo?.remark}
                        </Grid>
                    </Grid>
                    <br />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6} md={3}>
                            <b>見面事項</b>:<br />
                            {row?.title}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <b>開始時間</b>:<br />
                            {moment(row?.from).format('DD/MM/yyyy HH:mm:ss')}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <b>結束時間</b>:<br />
                            {moment(row?.to).format('DD/MM/yyyy HH:mm:ss')}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <b>約見地方</b>:<br />
                            {row?.place}
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <b>跟進人員</b>:<br />
                            {row?.followerName}
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>
        </div>
    );

    return [timeTableView, setRow, handleClickOpen];
}