import { Icon } from '@iconify/react';
import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
import peopleFill from '@iconify/icons-eva/people-fill';
import shoppingBagFill from '@iconify/icons-eva/shopping-bag-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import guiManagement from '@iconify/icons-carbon/gui-management';
import lockFill from '@iconify/icons-eva/lock-fill';
import personAddFill from '@iconify/icons-eva/person-add-fill';
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
import calendarIcon from '@iconify/icons-eva/calendar-outline';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: '網頁分析',
    path: '/analysis',
    icon: getIcon(pieChart2Fill)
  },
  {
    title: '客戶',
    path: '/user',
    icon: getIcon(personAddFill)
  },
  {
    title: '行事歷',
    path: '/calendar',
    icon: getIcon(calendarIcon)
  },
  // {
  //   title: '網誌',
  //   path: '/blog',
  //   icon: getIcon(fileTextFill)
  // },
  // {
  //   title: '網頁內容管理',
  //   path: '/cms',
  //   icon: getIcon(guiManagement)
  // }
];

export default sidebarConfig;
