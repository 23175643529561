import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import roundFilterList from '@iconify/icons-ic/round-filter-list';
import { status } from '../../../constant/dropdown';
// material
import { styled } from '@mui/material/styles';
import {
  Box,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  TextField,
  MenuItem
} from '@mui/material';
import useLoading from 'src/components/Loading';
import { deleteAppointment, deleteMember } from 'src/utils/apiUtil';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`
  }
}));

// ----------------------------------------------------------------------

AppointmentListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func
};

export default function AppointmentListToolbar({ numSelected, filterStatus, onFilterStatus, selected, cb }) {

  const [loading, handleOpen, handleClose] = useLoading();

  const handleDelete = async () => {
    handleOpen();
    let successCount = 0;
    selected.map(item => {

      deleteAppointment(item).then((res) => {
        successCount++;
        if (successCount === selected.length) {
          cb();
          handleClose();
        }
      })

      // deleteMember(item).then((res) => {
      //   successCount++;
      //   if (successCount === selected.length) {
      //     cb();
      //     handleClose();
      //   }

      // })
    })



  }

  return (
    <>
      {loading}
      <RootStyle
        sx={{
          ...(numSelected > 0 && {
            color: 'primary.main',
            bgcolor: 'primary.lighter'
          })
        }}
      >
        {numSelected > 0 ? (
          <Typography component="div" variant="subtitle1">
            {numSelected} selected
          </Typography>
        ) : (
          ""
          // <TextField
          //   fullWidth
          //   label="查詢項目"
          //   select
          //   value={filterStatus}
          //   onChange={onFilterStatus}
          // >
          //   <MenuItem key={'0'} value={''}>
          //     {'全部'}
          //   </MenuItem>
          //   {
          //     status.map((item, index) =>
          //       <MenuItem key={index} value={item}>
          //         {item}
          //       </MenuItem>
          //     )
          //   }
          // </TextField>
          // <SearchStyle
          //   value={filterName}
          //   onChange={onFilterName}
          //   placeholder="搜索客戶..."
          //   startAdornment={
          //     <InputAdornment position="start">
          //       <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
          //     </InputAdornment>
          //   }
          // />
        )}

        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton onClick={handleDelete}>
              <Icon icon={trash2Fill} />
            </IconButton>
          </Tooltip>
        ) : (
          ""
          // <Tooltip title="Filter list">
          //   <IconButton>
          //     <Icon icon={roundFilterList} />
          //   </IconButton>
          // </Tooltip>
        )}
      </RootStyle>
    </>
  );
}
