import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import guiManagement from '@iconify/icons-carbon/gui-management';
import peopleFill from '@iconify/icons-eva/people-fill';
import {Link as RouterLink, useHistory} from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
import { stringAvatar } from '../../utils/avatar';
//
import account from '../../_mocks_/account';
import { OktaAuth } from '@okta/okta-auth-js';
import { OktaConfig } from 'src/constant/oktaConfig';
const getIcon = (name) => <Icon icon={name} width={22} height={22} />;
// const oktaAuth = new OktaAuth(OktaConfig);

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: '網頁分析',
    icon: getIcon(peopleFill),
    linkTo: '/analysis',
  },
  {
    label: '客戶',
    icon: getIcon(peopleFill),
    linkTo: '/user',
  },
  {
    label: '行事歷',
    icon: getIcon(guiManagement),
    linkTo: '/calendar',
  },
  // {
  //   label: '網誌',
  //   icon: getIcon(fileTextFill),
  //   linkTo: '/blog',
  // },
  // {
  //   label: '網頁內容管理',
  //   icon: getIcon(guiManagement),
  //   linkTo: '/cms',
  // },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const history = useHistory();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const signOut = () => {
    localStorage.removeItem("dance-token")
    history.push("/login")
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        {/* <Avatar src={account.photoURL} alt="photoURL" /> */}
        <Avatar {...stringAvatar(JSON.parse(localStorage.getItem('okta-token-storage')).idToken?.claims?.name)} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        {/*<Box sx={{ my: 1.5, px: 2.5 }}>*/}
        {/*  <Typography variant="subtitle1" noWrap>*/}
        {/*    {JSON.parse(localStorage.getItem('okta-token-storage')).idToken?.claims?.name}*/}
        {/*  </Typography>*/}
        {/*  <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>*/}
        {/*    {JSON.parse(localStorage.getItem('okta-token-storage')).idToken?.claims?.email}*/}
        {/*  </Typography>*/}
        {/*</Box>*/}
        {/*<Divider sx={{ my: 1 }} />*/}

        {MENU_OPTIONS.map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={option.icon}
              sx={{
                mr: 2,
                width: 24,
                height: 24
              }}
            />

            {option.label}
          </MenuItem>
        ))}

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={() => {
            // oktaAuth.signOut({
            //   revokeAccessToken: true
            // })
            signOut()
          }}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
