// const OKTA_DOMAIN = "dev-79946355.okta.com"
// const CLIENT_ID = "0oa3lujegik9ErKby5d7"

// const OKTA_DOMAIN = "dev-14177240.okta.com"
// const CLIENT_ID = "0oaiy8der2bKjtoJo5d7"

const OKTA_DOMAIN = "okta-devok12.okta.com"
const CLIENT_ID = "HoJrach0fCuBMVs"

const CALLBACK_PATH = '/login/callback';

// const ISSUER = `http://${OKTA_DOMAIN}/oauth2/default`;
const ISSUER = `https://${OKTA_DOMAIN}/oauth2/default`;
const HOST = window.location.host;
const REDIRECT_URI = `http://${HOST}${CALLBACK_PATH}`;
const SCOPES = 'openid profile email';

export const OktaConfig = {
    issuer: ISSUER,
    clientId: CLIENT_ID,
    redirectUri: REDIRECT_URI,
    postLogoutRedirectUri: `https://${HOST}`,
    scopes: SCOPES.split(/\s+/)
};
