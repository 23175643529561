import {BrowserRouter, Route, Switch, useHistory, Redirect} from 'react-router-dom';
import {Security, SecureRoute, LoginCallback, useOktaAuth} from '@okta/okta-react';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import React from 'react';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import Register from './pages/Register';
import DashboardApp from './pages/DashboardApp';
import Products from './pages/Products';
import Blog from './pages/Blog';
import User from './pages/User';
import CMS from './pages/CMS';
import NotFound from './pages/Page404';
import {OktaConfig} from './constant/oktaConfig';
import Blogger from './pages/Blogger';
import Timetable from './pages/Timetable';
import AuthRoute from "./components/AuthRoute";

// ----------------------------------------------------------------------

// const oktaAuth = new OktaAuth(OktaConfig);

export default function Router() {

    const history = useHistory();
    const onAuthRequired = function () {
        history.push('/login')
    }
    const restoreOriginalUri = async (_oktaAuth, originalUri) => {
        history.replace(toRelativeUrl(originalUri, window.location.origin));
    };

    // return (
    //     <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
    //         <SecureRoute path="*">
    //             {
    //                 DashboardLayout(
    //                     <>
    //                         <SecureRoute path="/analysis" component={DashboardApp}/>
    //                         <SecureRoute path="/user" component={User}/>
    //                         <SecureRoute path="/calendar" component={Timetable}/>
    //                         {/* <SecureRoute path="/blog" component={Blogger} /> */}
    //                         {/* <SecureRoute path="/cms" component={CMS} /> */}
    //                         {/* <SecureRoute path="/login" exact component={User} /> */}
    //                         <SecureRoute path="/" exact component={User}/>
    //                         <Redirect from="/login" to="/"/>
    //                     </>
    //                 )
    //             }
    //         </SecureRoute>
    //
    //         <Route path='/login' component={Login}/>
    //         <Route path='/login/callback' component={LoginCallback}/>
    //
    //     </Security>
    // )

    return (
        <BrowserRouter>
            <Switch>
                <Route path='/login' component={Login}/>
                <Route path='/login/callback' component={LoginCallback}/>

                <DashboardLayout>
                    <Switch>
                        <AuthRoute path="/analysis" component={DashboardApp}/>
                        <AuthRoute path="/user" component={User}/>
                        <AuthRoute path="/calendar" component={Timetable}/>
                        {/* <Route path="/blog" component={Blogger} /> */}
                        {/* <Route path="/cms" component={CMS} /> */}
                        {/* <Route path="/login" exact component={User} /> */}
                        <AuthRoute path="/" exact component={User}/>
                        {/*<Redirect from="/login" to="/" />*/}
                    </Switch>
                </DashboardLayout>

                <Redirect to="/login" />
            </Switch>
        </BrowserRouter>
    );


    // return useRoutes([
    //     {
    //         path: '/dashboard',
    //         element: <DashboardLayout/>,
    //         children: [
    //             {element: <Navigate to="/dashboard/app" replace/>},
    //             {path: 'app', element: <DashboardApp/>},
    //             {path: 'user', element: <User/>},
    //             {path: 'products', element: <Products/>},
    //             {path: 'blog', element: <Blog/>}
    //         ]
    //     },
    //     {
    //         path: '/login/callback',
    //         element: <LogoOnlyLayout/>,
    //     },
    //     {
    //         path: '/',
    //         element: <LogoOnlyLayout/>,
    //         children: [
    //             {path: 'login', element: <Login/>},
    //             {path: 'register', element: <Register/>},
    //             {path: '404', element: <NotFound/>},
    //             {path: '/', element: <Navigate to="/dashboard"/>},
    //             {path: '*', element: <Navigate to="/404"/>}
    //         ]
    //     },
    //     {path: '*', element: <Navigate to="/404" replace/>}
    // ]);
}
