export const type = [
    '兒童及青少年課程',
    'Open Class',
    'Yoga'
];

export const source = [
    'Facebook',
    'Instagram',
    'Phone-in',
    'Walk-in',
    'Referral',
    'Roadshow',
    'WeChat',
    'Line',
    'Whatsapp',
    'Website'
]

export const occupation = [
    '會計',
    '廣告/市務',
    '銀行',
    '金融/保險',
    '設計',
    '教育/培訓',
    '能源',
    '工程',
    '飲食',
    '政府機構',
    '健康護理',
    '資訊科技',
    '法律',
    '製造',
    '地產',
    '零售',
    '電訊',
    '旅遊',
    '運輸',
    '學生',
    '待業',
]

export const age = [
    '17-22',
    '23-30',
    '31-40',
    '40 up'
]

export const childAge = [
    'Below 4',
    '4-6',
    '7-9',
    '10-13',
    '14-16'
]

export const gender = [
    'M', 'F'
]

export const region = [
    '',
    '香港',
    '九龍',
    '新界',
]

export const referral = [
    '搜尋廣告',
    'Banner 廣告',
    'Facebook專頁/廣告',
    'Instagram專頁/廣告',
    'Youtube影片',
    '海報或宣傳單張',
    '本校公開活動(宣傳活動/舞蹈演出)',
    '親友介紹'
]

export const step1Status = [
    '未檢閱 - 未檢閱',//default
    '已接聽 - 已預約',//info
    '已接聽 - 跟進', //info
    '已接聽 - 沒有興趣', //error
    '無人接聽 - 熄電話', //error
    '無人接聽 - 無人接聽', //error
    '號碼不正確 - 聲稱沒有登記', //error
    '號碼不正確 - 該電話號碼沒有用戶登記', //error 
    '號碼不正確 - 電話號碼不完整',  //error
];

export const step2Status = [
    '應約 - 已報名', //success
    '應約 - 需要跟進', //info
    '應約 - 銷售失敗', //error
    '甩底 - 無法聯絡', //error
    '甩底 - 不再感興趣', //error
    '甩底 - 跟進再預約日期', //info
    '甩底 - 已重新預約',//info
    '取消預約 - 跟進再預約日期',//info
    '取消預約 - 已重新預約',//info
    '取消預約 - 不再感興趣',//error
    '無效報名 - 無效報名',//error
    '再聯絡時間',//info
    '客人回電'//info
];


export const blogType = [
    {
        displayName: "新聞",
        value: "news",
    },
    {
        displayName: "動向",
        value: "direction",
    },
    {
        displayName: "演出",
        value: "show",
    },
    {
        displayName: "SEO News",
        value: "blog",
    }
]

export const shop = [
    '北角分校',
    '西環分校'
]