// material
import { filter } from 'lodash';
import {
    Card,
    Table,
    Stack,
    Avatar,
    Button,
    Checkbox,
    TableRow,
    TableBody,
    TableCell,
    Container,
    Typography,
    TableContainer,
    TablePagination,
    IconButton,
    Alert,
    AlertTitle
} from '@mui/material';
import editIcon from '@iconify/icons-eva/edit-2-outline';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
import { LoadingButton } from '@mui/lab';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { Dialog, DialogActions } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { type, source, occupation, age, childAge, referral, region, gender, status } from '../constant/dropdown';
import { getAppointmentList, postMember, updateMember } from 'src/utils/apiUtil';
import useLoading from './Loading';
import { statusColor } from '../utils/status';
import useCreateAppointmentForm from './AppointmentForm';
import * as moment from 'moment';
import AppointmentListToolbar from './_dashboard/user/AppointmentListToolbar';

const TABLE_HEAD = [
    { id: 'title', label: '查詢／見面事項', alignRight: false },
    { id: 'from', label: '開始時間', alignRight: false },
    { id: 'to', label: '結束時間', alignRight: false },
    { id: 'place', label: '約見地方', alignRight: false },
    // { id: 'contactPerson', label: '跟進人員', alignRight: false },
    { id: 'updatedAt', label: '創建日期', alignRight: false },
    { id: 'createdBy', label: '跟進人員', alignRight: false },
    { id: '' }
];


function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.status.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}


export default function useAppointmentForm(cb) {
    const [open, setOpen] = React.useState(true);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, handleLoadingOpen, handleLoadingClose] = useLoading();
    const [rowData, setRowData] = useState([]);

    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('updatedAt');
    const [filterName, setFilterName] = useState('');
    const [filterStatus, setFilterStatus] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [userId, setUserId] = useState('');
    const [createAppointmentFormDialog, setId, handleCreateAppointmentFormOpen] = useCreateAppointmentForm(() => {
        fetchAppointment();
    });

    const fetchAppointment = () => {
        getAppointmentList(userId).then(data => {
            setSelected([]);
            setRowData(data)
        })
    }

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = rowData.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    const handleClick = (event, _id) => {
        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleFilterByName = (event) => {
        setFilterName(event.target.value);
    };

    const handleFilterByStatus = (event) => {
        setFilterStatus(event.target.value);
    };

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

    const filteredUsers = applySortFilter(rowData, getComparator(order, orderBy), filterStatus);

    const isUserNotFound = filteredUsers.length === 0;

    const labelDisplayedRows = ({ from, to, count }) => {
        return `${from}-${to} , 共 ${count !== -1 ? count : `大於 ${to}`}`;
    }
    const createAppointment = () => {
        setId(userId)
        handleCreateAppointmentFormOpen()
    }
    const appointmentFormDialog = (
        <div>
            {loading}

            {/* <Dialog
                fullScreen={fullScreen}
                maxWidth={'xl'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {"會面"}
                </DialogTitle>
                <DialogContent> */}
            <IconButton onClick={() => createAppointment()} style={{ float: 'right' }}>
                <Icon icon={editIcon} />
            </IconButton>
            <Typography>
                <b>{"會面"}</b>
            </Typography>
            <br />
            {createAppointmentFormDialog}
            <Card style={{ minWidth: 800 }}>
                <AppointmentListToolbar
                    selected={selected}
                    numSelected={selected.length}
                    cb={() => {
                        getAppointmentList(userId).then(data => {
                            setSelected([]);
                            setRowData(data)
                        })
                    }}
                    filterStatus={filterStatus}
                    onFilterStatus={handleFilterByStatus}
                // filterName={filterName}
                // onFilterName={handleFilterByName}
                />

                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                        <Table>
                            <UserListHead
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={rowData.length}
                                numSelected={selected.length}
                                onRequestSort={handleRequestSort}
                                onSelectAllClick={handleSelectAllClick}
                            />
                            <TableBody>
                                {rowData && rowData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((row) => {
                                        const { _id, title, from, to, place, contactPerson, updatedAt, createdBy } = row;
                                        const isItemSelected = selected.indexOf(_id) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={_id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        checked={isItemSelected}
                                                        onChange={(event) => handleClick(event, _id)}
                                                    />
                                                </TableCell>
                                                <TableCell align="left">{title}</TableCell>
                                                <TableCell align="left">{moment(from).format('DD/MM/yyyy hh:mm:ss')}</TableCell>
                                                <TableCell align="left">{moment(to).format('DD/MM/yyyy hh:mm:ss')}</TableCell>
                                                <TableCell align="left">{place}</TableCell>
                                                {/* <TableCell align="left">{createdBy}</TableCell> */}
                                                <TableCell align="left">{moment(updatedAt).format('DD/MM/yyyy hh:mm:ss')}</TableCell>
                                                <TableCell align="left">{createdBy}</TableCell>
                                                <TableCell align="right">
                                                    {/* <IconButton onClick={(event) => handleBooking(event, row)}>
                                                                <Icon icon={calendarIcon} />
                                                            </IconButton>
                                                            <IconButton onClick={(event) => handleEdit(event, row)}>
                                                                <Icon icon={editIcon} />
                                                            </IconButton> */}
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>
                            {isUserNotFound && (
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="center" colSpan={8} sx={{ py: 3 }}>
                                            <SearchNotFound searchQuery={filterName} />
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>

                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={rowData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    labelRowsPerPage="每頁行數"
                    labelDisplayedRows={labelDisplayedRows}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>
            {/* </DialogContent>
            </Dialog> */}
        </div>
    );

    return [appointmentFormDialog, setRowData, setUserId, handleClickOpen, createAppointment, fetchAppointment];
}