
import { Modal, Box, Grid } from '@mui/material';
import React from 'react';


export default function useLoading() {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const loading = (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Grid
                    container
                    spacing={0}
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    style={{ minHeight: '70vh' }}
                >
                    <Grid item xs={3}>
                        <img src="https://lh3.googleusercontent.com/d/1UenlU7wXz3QTghLBKdDctskNc-Ke8OoZ" alt="loading..." width="100" />
                    </Grid>
                </Grid >
            </Box>
        </Modal>
    );

    return [loading, handleOpen, handleClose];
}