import axios from 'axios';

// const baseUrl = "http://127.0.0.1:5000";
const baseUrl = "https://dance-api.lxsmelon.top";
// const baseUrl = "https://dance-union-backend.herokuapp.com";

const config = {
    headers: {
        'Content-Type': 'application/json',
        // 'Access-Control-Request-Headers': '*',
    },
};

export function login(data) {
    return axios({
        url: `${baseUrl}/member/login`,
        method: 'post',
        ...config,
        data: data
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getPageDetail(page) {
    return axios({
        url: `${baseUrl}/danceUnion/${page}`,
        method: 'get',
        ...config,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function postPageDetail(page, data, id) {
    delete data._id;
    return axios({
        url: `${baseUrl}/danceUnion/${page}?id=${id}`,
        method: 'post',
        ...config,
        data: data
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function postGoogleDriveUrl(file, type) {
    const formData = new FormData();
    formData.append('file', file)
    return axios.post(
        `${baseUrl}/googleDrive?type=${type}`,
        formData,
        {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }
    )
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}


export function getBlogList(type) {
    return axios({
        url: `${baseUrl}/danceUnion/blog/${type}`,
        method: 'get',
        ...config,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function postBlog(data, type) {
    return axios({
        url: `${baseUrl}/danceUnion/blog/${type}?id=61a8f1b7fd76d3ded6cb8462`,
        method: 'post',
        ...config,
        data: data
    })
        .then(function (response) {
            return response.status === 200;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function updateBlog(data, type, id) {
    return axios({
        url: `${baseUrl}/danceUnion/blog/${type}/${id}?id=61a8f1b7fd76d3ded6cb8462`,
        method: 'put',
        ...config,
        data: data
    })
        .then(function (response) {
            return response.status === 200;
        })
        .catch(function (error) {
            console.log(error);
        });
}


export function deleteBlog(id, type) {
    return axios({
        url: `${baseUrl}/danceUnion/blog/${type}/${id}?id=61a8f1b7fd76d3ded6cb8462`,
        method: 'delete',
        ...config
    })
        .then(function (response) {
            return response.status === 200;
        })
        .catch(function (error) {
            console.log(error);
        });
}


export function getMemberForCall(type) {
    return axios({
        url: `${baseUrl}/danceUnion/blog/${type}`,
        method: 'get',
        ...config,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getMemberDetail(id) {
    return axios({
        url: `${baseUrl}/member/${id}`,
        method: 'get',
        ...config,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}


export function getMemberList() {
    return axios({
        url: `${baseUrl}/member`,
        method: 'get',
        ...config,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getMemberListToFollowUp() {
    return axios({
        url: `${baseUrl}/member/needFollowUp`,
        method: 'get',
        ...config,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function postMember(data) {
    return axios({
        url: `${baseUrl}/member`,
        method: 'post',
        ...config,
        data: data
    })
        .then(function (response) {
            return response.status === 200;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function updateMember(data, id) {
    return axios({
        url: `${baseUrl}/member/${id}`,
        method: 'put',
        ...config,
        data: data
    })
        .then(function (response) {
            return response.status === 200;
        })
        .catch(function (error) {
            console.log(error);
        });
}


export function deleteMember(id) {
    return axios({
        url: `${baseUrl}/member/${id}`,
        method: 'delete',
        ...config
    })
        .then(function (response) {
            return response.status === 200;
        })
        .catch(function (error) {
            console.log(error);
        });
}


export function getAppointmentList(userId) {
    return axios({
        url: `${baseUrl}/appointment?memberId=${userId}`,
        method: 'get',
        ...config,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getAllAppointmentList() {
    return axios({
        url: `${baseUrl}/appointment`,
        method: 'get',
        ...config,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getTodayAppointmentList() {
    return axios({
        url: `${baseUrl}/appointment/today`,
        method: 'get',
        ...config,
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function postAppointment(data, userId) {
    return axios({
        url: `${baseUrl}/appointment?memberId=${userId}`,
        method: 'post',
        ...config,
        data: data
    })
        .then(function (response) {
            return response.status === 200;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function updateAppointment(data, id) {
    return axios({
        url: `${baseUrl}/appointment/${id}`,
        method: 'put',
        ...config,
        data: data
    })
        .then(function (response) {
            return response.status === 200;
        })
        .catch(function (error) {
            console.log(error);
        });
}


export function deleteAppointment(id) {
    return axios({
        url: `${baseUrl}/appointment/${id}`,
        method: 'delete',
        ...config
    })
        .then(function (response) {
            return response.status === 200;
        })
        .catch(function (error) {
            console.log(error);
        });
}


// Google Analytics

export function getGAPage() {
    return axios({
        url: `${baseUrl}/googleAnalytics/page`,
        method: 'get',
        ...config
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getGABlog() {
    return axios({
        url: `${baseUrl}/googleAnalytics/blog`,
        method: 'get',
        ...config
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getGAVideo() {
    return axios({
        url: `${baseUrl}/googleAnalytics/video`,
        method: 'get',
        ...config
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}

export function getGASource() {
    return axios({
        url: `${baseUrl}/googleAnalytics/source`,
        method: 'get',
        ...config
    })
        .then(function (response) {
            return response.data;
        })
        .catch(function (error) {
            console.log(error);
        });
}




