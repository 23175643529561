// material
import { Stack, TextField, IconButton, InputAdornment, MenuItem, FormControlLabel, Checkbox, Typography, FormLabel, InputLabel, Select, OutlinedInput, Box, Chip, FormControl, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React, { useState } from 'react';
import { Button, Dialog, DialogActions } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { type, source, occupation, age, childAge, referral, region, gender, status, shop } from '../constant/dropdown';
import { postAppointment, postMember } from 'src/utils/apiUtil';
import useLoading from './Loading';
import DatePickerField from './DatePickerField';
import "react-datepicker/dist/react-datepicker.css";
import Scrollbar from './Scrollbar';

export default function useCreateAppointmentForm(cb) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, handleLoadingOpen, handleLoadingClose] = useLoading();
    const [id, setId] = useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const UserFormSchema = Yup.object().shape({
        title: Yup.string()
            .required('必填欄位'),
        from: Yup.string()
            .required('必填欄位'),
        to: Yup.string()
            .required('必填欄位'),
        place: Yup.string()
            .required('必填欄位'),
        // contactPerson: Yup.string()
        //     .required('必填欄位'),
        // createdBy: Yup.string(),
    });

    const formik = useFormik({
        initialValues: {
            title: '',
            from: '',
            to: '',
            place: '',
            contactPerson: '',
        },
        validationSchema: UserFormSchema,
        onSubmit: () => {
            handleLoadingOpen()
            postAppointment({
                ...formik.values,
                memberId: id,
                createdBy: JSON.parse(localStorage.getItem('okta-token-storage')).idToken?.claims?.name,
                getUserInfoUrl: JSON.parse(localStorage.getItem('okta-token-storage')).accessToken?.userinfoUrl,
                token: JSON.parse(localStorage.getItem('okta-token-storage')).accessToken?.value,
            }).then(data => {
                cb();
                handleReset();
                handleClose();
                handleLoadingClose();

            })
        }
    });

    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleReset } = formik;

    const appointmentFormDialog = (
        <div>
            {loading}
            <Dialog
                fullScreen={fullScreen}
                maxWidth={'xl'}
                fullWidth
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {"新增會面"}
                </DialogTitle>
                <DialogContent>
                    <Card style={{ minWidth: 800, minHeight: 450 }}>
                        <FormikProvider value={formik}>
                            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                                <Stack spacing={3} style={{ margin: 10 }}>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="查詢／見面事項"
                                            {...getFieldProps('title')}
                                            error={Boolean(touched.title && errors.title)}
                                            helperText={touched.title && errors.title}
                                        />
                                        <TextField
                                            fullWidth
                                            label="約見地方"
                                            {...getFieldProps('place')}
                                            error={Boolean(touched.place && errors.place)}
                                            helperText={touched.place && errors.place}
                                            select
                                        >
                                            {
                                                shop.map((item, index) =>
                                                    <MenuItem key={index} value={item}>
                                                        {item}
                                                    </MenuItem>
                                                )
                                            }
                                        </TextField>
                                    </Stack>
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <Box style={{ border: '2px solid #EEEEEE', borderRadius: 10, padding: 10, margin: 10 }}>
                                            <Typography>
                                                {'開始時間'}
                                            </Typography>
                                            <DatePickerField name="from" />
                                        </Box>
                                        <Box style={{ border: '2px solid #EEEEEE', borderRadius: 10, padding: 10, margin: 10 }}>
                                            <Typography>
                                                {'結束時間'}
                                            </Typography>
                                            <DatePickerField name="to" />
                                        </Box>

                                    </Stack>
                                    {/* 
                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            label="約見地方"
                                            {...getFieldProps('place')}
                                            error={Boolean(touched.place && errors.place)}
                                            helperText={touched.place && errors.place}
                                        />
                                        <TextField
                                            fullWidth
                                            label="跟進人員"
                                            {...getFieldProps('contactPerson')}
                                            error={Boolean(touched.contactPerson && errors.contactPerson)}
                                            helperText={touched.contactPerson && errors.contactPerson}
                                        />
                                    </Stack> */}


                                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                        <Button autoFocus onClick={handleClose} color="inherit" >
                                            退出
                                        </Button>
                                        <Button type={'submit'} autoFocus>
                                            新增
                                        </Button>
                                    </Stack>
                                </Stack>
                            </Form>

                        </FormikProvider>
                    </Card>
                </DialogContent>
            </Dialog>
        </div>
    );

    return [appointmentFormDialog, setId, handleClickOpen];
}