import { merge } from 'lodash';
import ReactApexChart from 'react-apexcharts';
// material
import { Card, CardHeader, Box } from '@mui/material';
//
import { BaseOptionChart } from '../../charts';

// ----------------------------------------------------------------------


export default function AppWebsiteVisits({ title, subheader, cols, rows, }) {
  const chartOptions = merge(BaseOptionChart(), {
    // stroke: { width: [0, 2, 3] },
    plotOptions: { bar: { columnWidth: '50%', borderRadius: 4 } },
    fill: { type: ['solid', 'solid', 'solid'] },
    // fill: { type: ['solid', 'gradient', 'solid'] },
    labels: cols.values,
    xaxis: { type: 'category' },
    colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
    tooltip: {
      shared: true,
      intersect: false,
      y: {
        formatter: (y) => {
          if (typeof y !== 'undefined') {
            return `${y.toFixed(0)} visits`;
          }
          return y;
        }
      }
    }
  });

  const CHART_DATA = [
    {
      name: '新用戶',
      type: 'column',
      data: rows[0].values
    },
    {
      name: '總訪客人數',
      type: 'column',
      data: rows[1].values
    },
    // {
    //   name: 'Team A',
    //   type: 'column',
    //   data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30]
    // },
    // {
    //   name: 'Team B',
    //   type: 'area',
    //   data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43]
    // },
    // {
    //   name: 'Team C',
    //   type: 'line',
    //   data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39]
    // }
  ];

  return (
    <Card>
      <CardHeader title={title} subheader={subheader} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart type="line" series={CHART_DATA} options={chartOptions} height={364} />
      </Box>
    </Card>
  );
}
