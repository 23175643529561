import { step1Status, step2Status } from '../constant/dropdown'

export const statusColor = (sta) => {
    const status = [...step1Status, ...step2Status];
    switch (sta) {
        case status[0]:
            return 'default';
        case status[1]:
        case status[2]:
        case status[14]:
        case status[15]:
        case status[16]:
        case status[17]:
        case status[20]:
        case status[10]:
        case status[21]:
            return 'info';
        case status[3]:
        case status[4]:
        case status[5]:
        case status[6]:
        case status[7]:
        case status[8]:
        case status[11]:
        case status[12]:
        case status[13]:
        case status[18]:
        case status[19]:
            return 'error';
        case status[9]:
            return 'success';
        default:
            return 'default';
    }
}