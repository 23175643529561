// material
import { Box, Grid, Container, Typography } from '@mui/material';
// components
import Page from '../components/Page';
import {
  AppTasks,
  AppNewUsers,
  AppBugReports,
  AppItemOrders,
  AppNewsUpdate,
  AppWeeklySales,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppCurrentSubject,
  AppConversionRates
} from '../components/_dashboard/app';
import { useEffect, useState } from 'react';
import { getGAPage, getGASource, getGAVideo, getGABlog } from 'src/utils/apiUtil';

// ----------------------------------------------------------------------

export default function DashboardApp() {

  const [gaPageDataRows, setGaPageDataRows] = useState(null);
  const [gaPageDataCols, setGaPageDataCols] = useState(null);

  const [gaBlogDataRows, setGaBlogDataRows] = useState(null);
  const [gaBlogDataCols, setGaBlogDataCols] = useState(null);

  const [gaVideoDataRows, setGaVideoDataRows] = useState(null);
  const [gaVideoDataCols, setGaVideoDataCols] = useState(null);

  const [gaSourceDataRows, setGaSourceDataRows] = useState(null);
  const [gaSourceDataCols, setGaSourceDataCols] = useState(null);

  useEffect(() => {
    fetchGoogleReport();
  }, []);

  async function fetchGoogleReport() {
    // Page
    getGAPage().then(data => {
      setGaPageDataRows(data.rows);
      setGaPageDataCols(data.cols);
    })
    // Blog
    getGABlog().then(data => {
      setGaBlogDataRows(data.rows);
      setGaBlogDataCols(data.cols);
    })
    // Video
    getGAVideo().then(data => {
      setGaVideoDataRows(data.rows);
      setGaVideoDataCols(data.cols);
    })
    // Source
    getGASource().then(data => {
      const sessionMedium = data.cols[0].values;
      const sessionSource = data.cols[1].values;
      const cols = []
      sessionMedium.map((item, index) => {
        cols.push(`${item} / ${sessionSource[index]}`)
      })
      setGaSourceDataRows(data.rows[0].values.map(value => parseInt(value)));
      setGaSourceDataCols(cols);
    })
  }

  return (
    <Page title="Dance Union">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">網頁分析</Typography>
        </Box>
        <Grid container spacing={3}>
          {
            gaPageDataRows && gaPageDataCols ?
              <>
                <Grid item xs={12} md={4} lg={8}>
                  {/* pagePathPlusQueryString && averageSessionDuration */}
                  <AppConversionRates title={'網頁逗留時間'} cols={gaPageDataCols[0]} rows={gaPageDataRows[2]} />
                </Grid>
                {
                  gaSourceDataCols && gaSourceDataRows ?
                    <Grid item xs={12} md={6} lg={4}>
                      <AppCurrentVisits title={'來源／媒介'} cols={gaSourceDataCols} rows={gaSourceDataRows} />
                    </Grid> : ""
                }
                <Grid item xs={12} md={12} lg={12}>
                  <AppWebsiteVisits title={'網頁流量'} cols={gaPageDataCols[0]} rows={[gaPageDataRows[0], gaPageDataRows[1]]} />
                </Grid>
              </>
              : ""
          }
        </Grid>
      </Container>
      <br /><br />
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">網誌分析</Typography>
        </Box>
        <Grid container spacing={3}>
          {
            gaBlogDataRows && gaBlogDataCols ?
              <>
                <Grid item xs={12} md={12} lg={12}>
                  {/* pagePathPlusQueryString && averageSessionDuration */}
                  <AppConversionRates title={'逗留時間'} cols={gaBlogDataCols[0]} rows={gaBlogDataRows[2]} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <AppWebsiteVisits title={'流量'} cols={gaBlogDataCols[0]} rows={[gaBlogDataRows[0], gaBlogDataRows[1]]} />
                </Grid>
              </>
              : ""
          }
        </Grid>
      </Container>
      <br /><br />
      {/* <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">影片分析</Typography>
        </Box>
        <Grid container spacing={3}>
          {
            gaVideoDataRows && gaVideoDataCols ?
              <>
                <Grid item xs={12} md={12} lg={12}>
  
                  <AppConversionRates title={'影片逗留時間'} cols={gaVideoDataCols[0]} rows={gaVideoDataRows[2]} />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <AppWebsiteVisits title={'影片流量'} cols={gaVideoDataCols[0]} rows={[gaVideoDataRows[0], gaVideoDataRows[1]]} />
                </Grid>
              </>
              : ""
          }
        </Grid>
      </Container> */}

      {/* <Grid item xs={12} md={6} lg={4}>
        <AppCurrentSubject />
      </Grid> */}
    </Page >
  );
}
