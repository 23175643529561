// material
import { Stack, TextField, IconButton, InputAdornment, MenuItem, FormControlLabel, Checkbox, Typography, FormLabel, InputLabel, Select, OutlinedInput, Box, Chip, FormControl } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Button, Dialog, DialogActions } from '@mui/material';
import { Icon } from '@iconify/react';
import plusFill from '@iconify/icons-eva/plus-fill';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { type, source, occupation, age, childAge, referral, region, gender, step1Status, step2Status } from '../constant/dropdown';
import { postMember, updateMember } from 'src/utils/apiUtil';
import useLoading from './Loading';

const status = [...step1Status, ...step2Status]

export default function useUpdateUserForm(cb, appointmentForm, createAppointment, handleBooking) {
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [loading, handleLoadingOpen, handleLoadingClose] = useLoading();
    const [row, setDataRow] = React.useState(
        {
            _id: '',
            name: '',
            type: '',
            source: '',
            email: '',
            phone: '',
            alternatePhone: '',
            age: '',
            childAge: '',
            occupation: '',
            gender: '',
            contactTime: '',
            referral: [],
            remark: '',
            region: '',
            status: '',
            appointments: []
        }
    )

    const setRow = (dataRow) => {
        setDataRow(dataRow)
        setReferralList(dataRow.referral);
        handleBooking(dataRow);
    }

    const handleClickOpen = () => {
        cb();
        setOpen(true);
    };

    const handleClose = () => {
        cb();
        setOpen(false);
    };

    const UserFormSchema = Yup.object().shape({
        name: Yup.string()
            .required('必填欄位'),
        type: Yup.string()
            .required('必填欄位'),
        source: Yup.string()
            .required('必填欄位'),
        email: Yup.string()
            .email('必須是有效的電子郵件地址'),
        phone: Yup.string()
            .required('必填欄位'),
        alternatePhone: Yup.string(),
        age: Yup.string(),
        childAge: Yup.string(),
        occupation: Yup.string(),
        gender: Yup.string(),
        contactTime: Yup.string(),
        referral: Yup.array(),
        remark: Yup.string(),
        region: Yup.string(),
        status: Yup.string(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: row,
        validationSchema: UserFormSchema,
        onSubmit: () => {
            handleLoadingOpen()
            updateMember({
                name: formik.values.name,
                type: formik.values.type,
                source: formik.values.source,
                email: formik.values.email,
                phone: formik.values.phone,
                alternatePhone: formik.values.alternatePhone,
                age: formik.values.age,
                childAge: formik.values.childAge,
                occupation: formik.values.occupation,
                gender: formik.values.gender,
                contactTime: formik.values.contactTime,
                referral: referralList,
                remark: formik.values.remark,
                region: formik.values.region,
                status: formik.values.status,
                appointments: formik.values.appointments,
            },
                row._id
            ).then(data => {
                cb();
                handleBooking(row);
                handleReset();
                handleClose();
                handleLoadingClose();

            })
        }
    });

    const [referralList, setReferralList] = React.useState([]);

    const handleChange = (event) => {
        const {
            target: { value },
        } = event;
        setReferralList(
            // On autofill we get a the stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const { errors, touched, handleSubmit, isSubmitting, getFieldProps, handleReset, setFieldValue } = formik;

    const updateUserFormDialog = (
        <div>
            {loading}
            <Dialog
                fullWidth
                fullScreen={fullScreen}
                maxWidth={'xl'}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    {"修改客戶資料"}
                </DialogTitle>
                <DialogContent>
                    <FormikProvider value={formik}>
                        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <Stack spacing={3} style={{ margin: 10 }}>
                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="名稱"
                                        {...getFieldProps('name')}
                                        error={Boolean(touched.name && errors.name)}
                                        helperText={touched.name && errors.name}
                                    />
                                    <TextField
                                        fullWidth
                                        label="性別"
                                        {...getFieldProps('gender')}
                                        error={Boolean(touched.gender && errors.gender)}
                                        helperText={touched.gender && errors.gender}
                                        select
                                    >
                                        {
                                            gender.map((item, index) =>
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        }
                                    </TextField>
                                    <TextField
                                        fullWidth
                                        label="年齡"
                                        {...getFieldProps('age')}
                                        error={Boolean(touched.age && errors.age)}
                                        helperText={touched.age && errors.age}
                                        select
                                    >
                                        {
                                            age.map((item, index) =>
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        }
                                    </TextField>
                                    <TextField
                                        fullWidth
                                        label="職業"
                                        {...getFieldProps('occupation')}
                                        error={Boolean(touched.occupation && errors.occupation)}
                                        helperText={touched.occupation && errors.occupation}
                                        select
                                    >
                                        {
                                            occupation.map((item, index) =>
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        }
                                    </TextField>

                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="聯絡號碼"
                                        {...getFieldProps('phone')}
                                        error={Boolean(touched.phone && errors.phone)}
                                        helperText={touched.phone && errors.phone}
                                    />

                                    <TextField
                                        fullWidth
                                        label="備用號碼"
                                        {...getFieldProps('alternatePhone')}
                                        error={Boolean(touched.alternatePhone && errors.alternatePhone)}
                                        helperText={touched.alternatePhone && errors.alternatePhone}
                                    />
                                    <TextField
                                        fullWidth
                                        label="電郵"
                                        {...getFieldProps('email')}
                                        error={Boolean(touched.type && errors.type)}
                                        helperText={touched.type && errors.type}
                                    />

                                    <TextField
                                        fullWidth
                                        label="來源"
                                        {...getFieldProps('source')}
                                        error={Boolean(touched.source && errors.source)}
                                        helperText={touched.source && errors.source}
                                        select
                                    >
                                        {
                                            source.map((item, index) => {
                                                return <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            }

                                            )
                                        }
                                    </TextField>

                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>


                                    <TextField
                                        fullWidth
                                        label="查詢項目"
                                        {...getFieldProps('type')}
                                        error={Boolean(touched.type && errors.type)}
                                        helperText={touched.type && errors.type}
                                        select
                                    >
                                        {
                                            type.map((item, index) =>
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        }
                                    </TextField>


                                    <TextField
                                        fullWidth
                                        label="兒童年齡"
                                        {...getFieldProps('childAge')}
                                        error={Boolean(touched.childAge && errors.childAge)}
                                        helperText={touched.childAge && errors.childAge}
                                        select
                                    >
                                        {
                                            childAge.map((item, index) =>
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        }
                                    </TextField>
                                    <TextField
                                        fullWidth
                                        label="地區"
                                        {...getFieldProps('region')}
                                        error={Boolean(touched.region && errors.region)}
                                        helperText={touched.region && errors.region}
                                        select
                                        defaultValue={''}
                                    >
                                        {
                                            region.map((item, index) =>
                                                <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            )
                                        }
                                    </TextField>
                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <FormControl sx={{ m: 1 }}>
                                        <InputLabel >得知途徑</InputLabel>
                                        <Select
                                            style={{ minWidth: 200 }}
                                            multiple
                                            value={referralList}
                                            onChange={handleChange}
                                            input={<OutlinedInput label="得知途徑" />}
                                            renderValue={(selected) => (
                                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                    {selected?.map((value) => (
                                                        <Chip key={value} label={value} />
                                                    ))}
                                                </Box>
                                            )}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 48 * 4.5 + 8,
                                                        width: 250,
                                                    },
                                                }
                                            }}
                                        >
                                            {referral?.map((item, index) => (
                                                <MenuItem
                                                    key={index}
                                                    value={item}
                                                >
                                                    {item}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="狀態"
                                        {...getFieldProps('status')}
                                        onChange={(event) => {
                                            setFieldValue('status', event.target.value)
                                            handleBooking(row);
                                            if (event.target.value === status[1] || event.target.value === status[15]
                                                || event.target.value === status[17]) {

                                                createAppointment()
                                            }

                                        }}
                                        error={Boolean(touched.status && errors.status)}
                                        helperText={touched.status && errors.status}
                                        select
                                    >
                                        {
                                            (row.status !== step1Status[1] && step1Status.includes(row.status)) && step1Status.map((item, index) => {
                                                return <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            })
                                        }
                                        {
                                            (row.status === step1Status[1]) && <MenuItem value={step1Status[1]}>
                                                {step1Status[1]}
                                            </MenuItem>
                                        }
                                        {
                                            (row.status === step1Status[1] || step2Status.includes(row.status)) && step2Status.map((item, index) => {
                                                return <MenuItem key={index} value={item}>
                                                    {item}
                                                </MenuItem>
                                            })
                                        }
                                    </TextField>
                                </Stack>

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <TextField
                                        fullWidth
                                        label="備注"
                                        {...getFieldProps('remark')}
                                        error={Boolean(touched.remark && errors.remark)}
                                        helperText={touched.remark && errors.remark}
                                    />
                                </Stack>


                                {
                                    formik.values.status !== status[0] && formik.values.status !== status[2]
                                        && formik.values.status !== status[3] && formik.values.status !== status[4]
                                        && formik.values.status !== status[5] && formik.values.status !== status[6]
                                        && formik.values.status !== status[7] && formik.values.status !== status[8]
                                        ? <Stack>
                                            {appointmentForm}
                                        </Stack> : ""
                                }

                                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                                    <Button autoFocus onClick={handleClose} color="inherit" >
                                        退出
                                    </Button>
                                    <Button type={'submit'} autoFocus>
                                        修改
                                    </Button>
                                </Stack>
                            </Stack>
                        </Form>

                    </FormikProvider>
                </DialogContent>
            </Dialog>
        </div>
    );

    return [updateUserFormDialog, setRow, handleClickOpen];
}