import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useEffect, useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  IconButton,
  Alert,
  AlertTitle,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { Icon } from '@iconify/react';
import calendarIcon from '@iconify/icons-eva/calendar-outline';
import editIcon from '@iconify/icons-eva/edit-2-outline';
// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../components/_dashboard/user';
//
// import USERLIST from '../_mocks_/user';
import { stringAvatar } from '../utils/avatar';
import { statusColor } from '../utils/status';
import useUserForm from 'src/components/UserForm';
import { getAppointmentList, getMemberList, getMemberListToFollowUp, getTodayAppointmentList } from 'src/utils/apiUtil';
import useUpdateUserForm from 'src/components/UpdateUserForm';
import useAppointmentForm from 'src/components/CreateAppointment';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: '名稱', alignRight: false },
  { id: 'type', label: '查詢項目', alignRight: false },
  { id: 'phone', label: '電話', alignRight: false },
  { id: 'status', label: '狀態', alignRight: false },
  { id: 'updatedDate', label: '更新日期', alignRight: false },
  { id: 'createdDate', label: '建立日期', alignRight: false },
  { id: '' }
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    let tempList = array;
    Object.keys(query).map(key => {
      tempList = filter(tempList, (_user) => _user[key].toLowerCase().indexOf(query[key].toLowerCase()) !== -1);
    })
    return tempList;
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('createdBy');
  const [filterName, setFilterName] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [userFormDialog] = useUserForm(() => {
    fetchUserListToFollowUp()
    fetchUserList()
    setSelected([])
  });
  const [expanded, setExpanded] = useState('followUp');
  const [USERLIST, setUSERLIST] = useState([]);
  const [followUpList, setFollowUpList] = useState([]);
  const [todayMeeting, setTodayMeetingList] = useState([]);
  const [init, setInit] = useState(true);

  const [appointmentFormDialog, setRowData, setUserId, setAppointmentFormOpen, createAppointment, fetchAppointment] = useAppointmentForm(() => {
    fetchUserListToFollowUp()
    fetchUserList()
    setSelected([])
  });

  const handleBooking = (row) => {
    getAppointmentList(row._id).then(data => {
      setRowData(data);
      setUserId(row._id);
      setAppointmentFormOpen();
    })
  }

  const [updateUserFormDialog, setRow, setEditFormOpen] = useUpdateUserForm(() => {
    fetchUserListToFollowUp()
    fetchUserList()
    setSelected([])
  },
    appointmentFormDialog,
    createAppointment,
    handleBooking);

  async function fetchUserList() {
    let response = await getMemberList();
    setUSERLIST(response);
  }

  async function fetchUserListToFollowUp() {
    let response = await getMemberListToFollowUp();
    setFollowUpList(response);
  }

  async function fetchTodayMeetingList() {
    let response = await getTodayAppointmentList();
    setTodayMeetingList(response);
  }

  useEffect(() => {
    if (init) {
      fetchUserList();
      fetchUserListToFollowUp()
      fetchTodayMeetingList()
      setInit(false);
    }

  }, [USERLIST, init])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleEdit = (event, row) => {
    setRow(row);
    setEditFormOpen();
  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n._id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, _id) => {
    const selectedIndex = selected.indexOf(_id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, _id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleFollowUp = (event) => {

  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleFilterByStatus = (event) => {
    console.log('123');
    setFilterStatus({
      ...filterStatus,
      [event.target.name]: event.target.value
    });
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterStatus);

  const isUserNotFound = filteredUsers.length === 0;

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from}-${to} , 共 ${count !== -1 ? count : `大於 ${to}`}`;
  }

  return (
    <>
      {/* {appointmentFormDialog} */}
      {updateUserFormDialog}
      <Page title="Customer | Dance Union">
        <Container >

          <Card style={{ border: "2px solid red" }}>
            <Alert severity="error">
              <AlertTitle><h3>優先處理客戶：</h3></AlertTitle>
            </Alert>
            <Accordion expanded={expanded === 'followUp'} onChange={handleChange('followUp')}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
              >
                <div style={{ color: 'red' }}>
                  <h3>請盡快進行跟進以下客戶：</h3>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <UserListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={USERLIST.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        disableCheckBox={false}
                      />
                      <TableBody>
                        {followUpList
                          .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                          .map((row) => {
                            const { _id, name, type, phone, email, remark, source, alternatePhone, age, status, updatedDate, childAge, occupation, gender, contactTime, referral, createdDate } = row;
                            const isItemSelected = selected.indexOf(_id) !== -1;

                            return (
                              <TableRow
                                hover
                                key={_id}
                                tabIndex={-1}
                                role="checkbox"
                                selected={isItemSelected}
                                aria-checked={isItemSelected}
                              >
                                <TableCell component="th" scope="row" padding="none">
                                  <Stack direction="row" alignItems="center" spacing={2}>
                                    {/* <Avatar {...stringAvatar(name)} /> */}
                                    <Typography variant="subtitle2" noWrap>
                                      {name}
                                    </Typography>
                                  </Stack>
                                </TableCell>
                                <TableCell align="left">{type}</TableCell>
                                <TableCell align="left">{phone.replaceAll('undefined','')}</TableCell>
                                <TableCell align="left">
                                  <Label
                                    variant="ghost"
                                    color={statusColor(status)}
                                  >
                                    {status}
                                  </Label>
                                </TableCell>
                                <TableCell align="left">{createdDate}</TableCell>
                                <TableCell align="left">{updatedDate}</TableCell>
                                <TableCell align="right">
                                  {/* <IconButton onClick={(event) => handleBooking(row)}>
                                    <Icon icon={calendarIcon} />
                                  </IconButton> */}
                                  <IconButton onClick={(event) => handleEdit(event, row)}>
                                    <Icon icon={editIcon} />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        {emptyRows > 0 && (
                          <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>

                </Scrollbar>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={followUpList.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  labelRowsPerPage="每頁行數"
                  labelDisplayedRows={labelDisplayedRows}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />

              </AccordionDetails>
            </Accordion>
          </Card>
          <br />
          <Alert severity="info">
            <AlertTitle>行事歷</AlertTitle>
            今天你有<strong>{todayMeeting.length}</strong>個會面
          </Alert>
          <br />

          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              客戶
            </Typography>
            {userFormDialog}
          </Stack>

          <Card>
            <UserListToolbar
              selected={selected}
              numSelected={selected.length}
              cb={() => {
                fetchUserListToFollowUp()
                fetchUserList()
                setSelected([])
              }}
              filterStatus={filterStatus}
              onFilterStatus={handleFilterByStatus}
            // filterName={filterName}
            // onFilterName={handleFilterByName}
            />

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={USERLIST.length}
                    numSelected={selected.length}
                    onRequestSort={handleRequestSort}
                    onSelectAllClick={handleSelectAllClick}
                  />
                  <TableBody>
                    {filteredUsers
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row) => {
                        const { _id, name, type, phone, email, remark, source, alternatePhone, age, status, updatedDate, childAge, occupation, gender, contactTime, referral, createdDate } = row;
                        const isItemSelected = selected.indexOf(_id) !== -1;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell padding="checkbox">
                              <Checkbox
                                checked={isItemSelected}
                                onChange={(event) => handleClick(event, _id)}
                              />
                            </TableCell>
                            <TableCell component="th" scope="row" padding="none">
                              <Stack direction="row" alignItems="center" spacing={2}>
                                {/* <Avatar {...stringAvatar(name)} /> */}
                                <Typography variant="subtitle2" noWrap>
                                  {name}
                                </Typography>
                              </Stack>
                            </TableCell>
                            <TableCell align="left">{type}</TableCell>
                            <TableCell align="left">{phone.replaceAll('undefined','')}</TableCell>
                            <TableCell align="left">
                              <Label
                                variant="ghost"
                                color={statusColor(status)}
                              >
                                {status}
                              </Label>
                            </TableCell>
                            <TableCell align="left">{updatedDate}</TableCell>
                            <TableCell align="left">{createdDate}</TableCell>
                            <TableCell align="right">
                              {/* <IconButton onClick={(event) => handleBooking(event, row)}>
                                <Icon icon={calendarIcon} />
                              </IconButton> */}
                              <IconButton onClick={(event) => handleEdit(event, row)}>
                                <Icon icon={editIcon} />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  {isUserNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={filterName} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

            </Scrollbar>

            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={USERLIST.length}
              rowsPerPage={rowsPerPage}
              page={page}
              labelRowsPerPage="每頁行數"
              labelDisplayedRows={labelDisplayedRows}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Card>
        </Container>
      </Page>
    </>
  );
}
